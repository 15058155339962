import React from 'react';
import {Link} from 'gatsby';
import slack from '../../images/integrations/hexomatic-integrations-icons/slack.svg';
import telegram from '../../images/integrations/hexomatic-integrations-icons/telegram.svg';
import google from '../../images/integrations/hexomatic-integrations-icons/google.svg';
import googleSheetsExport from '../../images/integrations/hexomatic-integrations-icons/google-sheets-export.svg';
// import zapier from "../../images/integrations/zapier.png";
import pabbly from '../../images/integrations/hexomatic-integrations-icons/pabbly-connect.svg';
import webhook from '../../images/integrations/hexomatic-integrations-icons/webhook.svg';
import api from '../../images/integrations/hexomatic-integrations-icons/api.svg';
import discord from '../../images/integrations/hexomatic-integrations-icons/discord.svg';
import zapier from '../../images/integrations/hexomatic-integrations-icons/zapier.svg';
import konnectzit from '../../images/integrations/hexomatic-integrations-icons/konnectzit.svg';
import integrately from '../../images/integrations/hexomatic-integrations-icons/integrately.svg';
import make from '../../images/integrations/hexomatic-integrations-icons/make-integromat.svg';
import teams from '../../images/integrations/hexomatic-integrations-icons/ms-teams.svg';
import wordpress from '../../images/integrations/hexomatic-integrations-icons/wordpress.svg';
import sql from '../../images/integrations/hexomatic-integrations-icons/sql-database.svg';
import googleDrive from '../../images/integrations/hexomatic-integrations-icons/google-drive.svg';
import googleSheetsImport from '../../images/integrations/hexomatic-integrations-icons/google-sheets-import.svg';
import './styles.scss';

// { name: 'Telegram Integration', linkTo: '/telegrambot' },
//     { name: 'Slack Integration', linkTo: '/slackbot' },
//     { name: 'Zapier Integration', linkTo: '/zapier' },
//     { name: 'Pabbly Integration', linkTo: '/pabbly' },
const PageIntegrations = () => {
  const points = [
    {
      //name: 'Telegram',
      logo: telegram,
      linkTo: '/telegrambot',
    },
    {
      //name: 'Slack',
      logo: slack,
      linkTo: '/slackbot',
    },
    {
      //name: 'Gmail',
      logo: google,
      linkTo: '/google',
    },
    {
      //name: 'Discord',
      logo: discord,
      linkTo: '/discord',
    },

    {
      // name: 'Pabbly Connect',
      logo: pabbly,
      linkTo: '/pabbly-connect',
    },
    {
      // name: 'Webhook',
      logo: webhook,
      linkTo: '/webhook',
    },
    {
      //  name: 'API',
      logo: api,
      linkTo: '/api-documentation',
    },
    {
      //name: 'Zapier',
      logo: zapier,
      linkTo: '/zapier',
    },
    {
      //name: 'KonnectzIT',
      logo: konnectzit,
      linkTo: '/konnectzit',
    },

    {
      //name: 'Make, formerly Integromat',
      logo: make,
      linkTo: '/make',
    },
    {
      //name: 'Integrately',
      logo: integrately,
      linkTo: '/integrately',
    },
    {
      //name: 'MS Teams',
      logo: teams,
      linkTo: '/microsoft-teams',
    },

    {
      // name: 'WordPress',
      logo: wordpress,
      linkTo: '/wordpress',
    },
    {
      // name: 'SQL Database',
      logo: sql,
      linkTo: '/sql-database',
    },
    {
      // name: 'Google Drive',
      logo: googleDrive,
      linkTo: '/google-drive',
    },
    {
      //name: 'Google Sheets Import',
      logo: googleSheetsImport,
      linkTo: '/google-sheets-import',
    },
    {
      //name: 'Google Sheets (Export / Sync)',
      logo: googleSheetsExport,
      linkTo: '/google-sheets-export-sync',
    },
  ];

  return (
    <div className="pageIntegrations text-center mb-30" style={{width: '100%'}}>
      <div className="integration-mobile row m-0">
        {points.map(item => {
          return (
            <div key={item.name} className="integration-item p-2">
              <div className="promo_item">
                {item.linkTo ? (
                  <Link to={item.linkTo || ''}>
                    <img src={item.logo} alt={item.name} width={150} height={150} />
                    <p>{item.name}</p>
                  </Link>
                ) : (
                  <>
                    <img src={item.logo} alt={item.name} width={150} height={150} />
                    <p>{item.name}</p>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PageIntegrations;
